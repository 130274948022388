<template>
  <div class="d-flex flex-column pt-4">
    <div class="d-flex justify-content-between">
      <h1>Audit Logs</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <!-- query form -->
    <div class="d-flex flex-column pt-4">
      <b-card-group>
        <b-card class="bg-surface-2">
          <b-row>
            <b-col lg="4">
              <div>Date Time Range</div>
              <date-range-picker
                :autoApply="false"
                @update="getListSelect"
                :opens="opens"
                ref="picker"
                class="w-100"
                v-model="queryForm.dateRange"
                :locale-data="{
                  firstDay: 1,
                  format: 'dd-mm-yyyy HH:mm:ss'
                }"
                :time-picker-increment="1"
                :timePicker="true"
                :time-picker-seconds="true"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | dateTime }} -
                  {{ picker.endDate | dateTime }}
                </template>
              </date-range-picker>
            </b-col>
            <b-col lg="4">
              <div>Service Name</div>
              <v-select
                class="filter-select mr-2"
                v-model="queryForm.service_name"
                :label="'name'"
                :options="servicesOptions"
                :reduce="(item) => item.value"
                @input="getData"
              />
            </b-col>
            <b-col lg="4">
              <div>User Name</div>
              <v-select
                class="filter-select mr-2"
                v-model="queryForm.user_name"
                :label="'name'"
                :options="userNameOptions"
                :reduce="(item) => item.value"
                @input="getData"
              />
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end" no-gutters>
            <b-button
              size="sm"
              variant="dark"
              @click="clearFilter"
              class="d-flex justify-content-end mr-1"
              >Clear
            </b-button>
            <b-button size="sm" variant="light" @click="getData">
              Search
            </b-button>
          </b-row>
        </b-card>
      </b-card-group>

      <div class="d-flex justify-content-end pt-4 pb-2">
        <download-excel
          class="ml-1 btn btn-secondary"
          :class="result.length === 0 ? 'disabled' : null"
          :data="result"
          :fields="jsonVMSFields"
          worksheet="My Worksheet"
          type="csv"
          :name="
            queryForm.dateRange.startDate +
            ' to ' +
            queryForm.dateRange.endDate +
            ' Audit Logs.csv'
          "
        >
          <i class="fas fa-file-csv"></i> Export as CSV
        </download-excel>
      </div>

      <div class="d-flex">
        <!-- Main table element -->
        <b-table
          class="tableBorder"
          striped
          small
          show-empty
          sticky-header
          stacked="md"
          :busy="busy"
          :items.sync="result"
          :fields="resultTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="searchTerms"
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          :filter-included-fields="[]"
          @sort-changed="sortChanged"
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="data">
            <div v-if="perPage != 'all'">
              {{ data.index + 1 + (currentPage - 1) * perPage }}
            </div>
            <div v-else>
              {{ data.index + 1 }}
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | unixToDateTime }}
          </template>
          <template #cell(user_name)="data">
            {{ data.item.user_name | humanizeText }}
          </template>
          <template #cell(description)="data">
            {{ data.item.description }}
          </template>
          <template #cell(service_name)="data">
            {{ data.item.service_name | humanizeText }}
          </template>
        </b-table>
      </div>
      <!--pagination-->
      <div class="d-flex justify-content-between w-100" v-if="!busy && !search">
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="handlePerPageChange"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// code here
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import JsonExcel from "vue-json-excel";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    downloadExcel: JsonExcel,
    DateRangePicker
  },
  data: function () {
    let yesterday = moment().subtract(1, "days").toDate();
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);

    let today = moment().toDate();
    return {
      opens: "right",
      searchTerms: null,
      search: false,

      busy: false,
      name: null,
      value: null,
      result: [],
      resultTableFields: [
        {
          key: "index",
          label: "No.",
          thStyle: { width: "3%" }
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true,
          formatter: "getFormatedDateTime",
          thStyle: { width: "auto" }
        },
        {
          key: "user_name",
          label: "Name",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
          thStyle: { width: "auto" }
          // resizable: false,
        },
        { key: "service_name", label: "Service", thStyle: { width: "auto" } }
      ],
      servicesOptions: [],
      userNameOptions: [],

      //sort
      // pagination
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      // pageOptions: [5, 10, 15],
      pageOptions: [5, 10, 15, { value: "all", text: "All" }],

      queryForm: {
        service_name: null,
        user_name: null,
        dateRange: {
          startDate: yesterday,
          endDate: today
        }
      },
      sortDesc: null,
      sortBy: null,
      sortDirection: "desc",

      // export to CSV
      jsonVMSFields: {
        Date: {
          field: "created_at",
          callback: (value) => {
            try {
              return moment(value * 1000).format("YYYY-MM-DD HH:mm:ss");
            } catch (e) {
              return "-";
            }
          }
        },
        Name: {
          field: "user_name",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        Description: {
          field: "description"
        },
        Service: {
          field: "service_name",
          callback: (value) => {
            try {
              return _.startCase(value);
            } catch (e) {
              return "-";
            }
          }
        },
        Data: {
          field: "data",
          callback: (value) => {
            try {
              const obj = JSON.stringify(value);
              return " " + obj + "\r";
            } catch (e) {
              return "-";
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 15;
      }
      this.getData();
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
    }
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData() {
      this.getPagination();
      this.getAuditLogs();
    },
    getAuditLogs: function () {
      this.busy = true;

      let $this = this;
      let query = {};
      query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
        user_name: $this.queryForm.user_name,
        service_name: $this.queryForm.service_name
      };

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/auditlogservice/audit-logs/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let logservice_url in result) {
              validUrl = logservice_url;
              this.busy = false;
              this.search = false;
              break outer_loop;
            }
            if (result[validUrl] != undefined) {
              if (this.perPage == "all") {
                this.result = result[validUrl];
              } else {
                this.result = result[validUrl]["audit_logs"];

                if (this.result == undefined) {
                  this.result = result[validUrl];
                }
              }
              $this.busy = false;
            }
          }
          this.getListSelect();
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    getPagination: function () {
      let $this = this;
      let query = {};
      if (this.perPage === "all") {
        query = {
          start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
          end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
          user_name: $this.queryForm.user_name,
          service_name: $this.queryForm.service_name
        };
      } else {
        query = {
          start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
          end_date: moment($this.queryForm.dateRange.endDate).toISOString(),
          user_name: $this.queryForm.user_name,
          service_name: $this.queryForm.service_name,
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }

      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/auditlogservice/audit-logs/?";

      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let logservice_url in result) {
              validUrl = logservice_url;
              this.busy = false;
              this.search = false;
              break outer_loop;
            }
          }

          if (result[validUrl]["total"] !== undefined) {
            this.totalRows = parseInt(result[validUrl]["total"]);
          }

          if (result[validUrl]["page"] !== undefined) {
            this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            this.perPage = parseInt(result[validUrl]["size"]);
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    getListSelect: function () {
      let $this = this;
      let query = {};
      query = {
        start_date: moment($this.queryForm.dateRange.startDate).toISOString(),
        end_date: moment($this.queryForm.dateRange.endDate).toISOString()
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");
      let API_URL = $this.getAPIServerURL + "/api/auditlogservice/audit-logs/?";
      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let logservice_url in result) {
              validUrl = logservice_url;
              this.busy = false;
              this.search = false;
              break outer_loop;
            }
            if (result[validUrl] != undefined) {
              for (let key_result in result[validUrl]) {
                let output = [];
                output.push({
                  value: result[validUrl][key_result].service_name,
                  name: _.startCase(result[validUrl][key_result].service_name)
                });
                output.forEach(function (item) {
                  let existing = $this.servicesOptions.filter(function (v, i) {
                    return v.name == item.name;
                  });
                  if (existing.length == 0) {
                    if (typeof item.value == "string") {
                      $this.servicesOptions.push(item);
                    }
                  }
                });

                let output2 = [];
                output2.push({
                  value: result[validUrl][key_result].user_name,
                  name: _.startCase(result[validUrl][key_result].user_name)
                });
                output2.forEach(function (item) {
                  let existing2 = $this.userNameOptions.filter(function (v, i) {
                    return v.name == item.name;
                  });
                  if (existing2.length == 0) {
                    if (typeof item.value == "string") {
                      $this.userNameOptions.push(item);
                    }
                  }
                });
              }
            }
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    mySortCompare(a, b, key) {
      /* console.log("a", a);
      console.log("b", b);
      console.log("key", key); */
      let result = null;
      result = a[key] > b[key];
      console.log("result", result);
      return result;
      /* if (key === 'date') {
        // Assuming the date field is a `Date` object, subtraction
        // works on the date serial number (epoch value)
        return a[key] - b[key]
      } else {
        // Let b-table handle sorting other fields (other than `date` field)
        return false
      } */
    },

    //sort
    sortChanged(e) {
      console.log("inside", e);
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      if (this.sortBy == "created_at") {
        if (this.sortDesc) {
          this.result = _.orderBy(this.result, this.sortBy, "desc");
        }
        if (!this.sortDesc) {
          this.result = _.orderBy(this.result, this.sortBy, "asc");
        }
      } else {
        if (this.sortDesc) {
          this.result.sort((x, y) =>
            y[this.sortBy].localeCompare(x[this.sortBy])
          );
        } else {
          this.result.sort((x, y) =>
            x[this.sortBy].localeCompare(y[this.sortBy])
          );
        }
      }
    },

    clearFilter: function () {
      let yesterday = moment().subtract(1, "days").toDate();
      yesterday.setHours(0);
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);
      yesterday.setMilliseconds(0);
      this.$router.push({
        name: this.$route.query.parents_url
      });
      let today = moment().toDate();
      this.servicesOptions = [];
      this.userNameOptions = [];
      this.queryForm = {
        service_name: null,
        user_name: null,
        dateRange: {
          startDate: yesterday,
          endDate: today
        }
      };
      this.getData();
    },
    /* handle page change */
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    startDownloadCSV() {
      if (this.result.length > 0) {
        this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text:
            "We are generating CSV report with " +
            this.result.length +
            " events",
          message_type: "info"
        });
      }
    },
    finishDownloadCSV() {
      this.$store.dispatch("session/addGlobalAlertMessage", {
        message_text:
          "CSV report with " +
          this.result.length +
          " events has been downloaded successfully",
        message_type: "info"
      });
    }
  }
};
</script>
<style lang="scss">
@import "../App";
</style>
